import { createRouter,createWebHistory} from 'vue-router';
import HomePage from '@/views/index';
// 路由信息
export const routes = [
  {
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    name: 'homePage',
    component: HomePage,
    meta: { title: '首页', icon: 'house'}
  },
  { path: '/:pathMatch(.*)', redirect: '/homepage', hidden: true }
];
// Vue2.X的base选项被移除，根路径在此处配置
// const routerHistory = createWebHistory('/v3-plus')

// 导出路由
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;