<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import '@/assets/flex.css';
html, body, p, h1 {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Microsoft YaHei, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
