<template>
  <div class="container">
    <div class="row jc-center ai-center" style="width:100%;">
      <div class="row jc-between ai-center logo-wrap">
        <div class="row jc-start ai-center">
          <img src="@/assets/logo.png" alt="logo" class="logo" />
          <div class="name">全员考课APP</div>
        </div>
        <div class="xx-btn">APP介绍·全员考课</div>
      </div>
    </div>
    <div class="row jc-center ai-center banner-wrap">
      <div class="col jc-start ai-atsrt banner-desc" style="width: 800px;">
        <p class="desc-title">全员考课APP</p>
        <p class="desc-title2">公务员全员考课管理,让工作变得更轻松愉快</p>
        <p class="desc-info">
          公务员全员考课（Daily Performance Appraisal）全过程数字化平台（以下简称“考核平台”或“平台”）
          提供手机APP+Web端管理后台两个使用端，运用区块链、云分析等技术，对公务员全员考课全过程的任务指标、考评、
          汇报总结等工作流程进行数字化升级，使工作留痕，增强信息的传递性，以及提升工作过程的实时性、积极性、有效性、公平性和监督性。
        </p>
        <div class="row jc-start ai-center" style="margin-top:15px;">
          <!-- <div class="banner-btn1">即刻开始</div>
          <div class="banner-btn2">了解更多</div> -->
          <img src="@/assets/qrcode.png" alt="qrcode" class="qrcode" />
        </div>
      </div>
      <img src="@/assets/banner5.png" alt="banner" class="banner1" />
    </div>
    <div class="row jc-center ai-center banner-wrap2">
      <img src="@/assets/banner2.png" alt="banner" class="banner2" />
      <div class="col jc-start ai-atsrt banner-desc">
        <img src="@/assets/logo.png" alt="logo" class="logo" style="margin-top:75px;margin-bottom:20px;" />
        <p class="desc2-title">全员考课全过程数字化平台</p>
        <p class="desc-info" style="color:#4F5059;opacity:1;">
          考核平台提供任务管理、错情记录、个人先进优秀积分、会议考勤、荣誉币激励、群众扫码评价、
          自动汇总生成季度工作小结、待办事项提醒、单位通知公告等功能，实现对全员考课的全过程记录。
        </p>
      </div>    
    </div>
    <div class="col jc-start ai-center banner-wrap3">
      <div class="desc3-title">我们的优势</div>
      <p class="desc-info" style="width:900px;color:#4F5059;opacity:1;">
        运用区块链、云分析等技术，对公务员全员考课全过程的任务指标、考评、汇报总结等工作流程进行数字化升级，
        使工作留痕，增强信息的传递性，以及提升工作过程的实时性、积极性、有效性、公平性和监督性。
      </p>
      <img src="@/assets/banner3.png" alt="banner" class="banner3" />
    </div>
    <div class="row jc-center ai-center banner-wrap4">
      <div class="col jc-start ai-atsrt banner-desc">
        <p class="desc-title" style="color:#332218;">即刻合作</p>
        <p class="desc-title2" style="color:#332218;">全生态覆盖 全世界在等你</p>
        <p class="desc-info" style="color:#332218;">
          我们的任务管理数据汇总、一键催办和提醒等服务，能够安全可靠地将为您提供优质的客户服务，
          更前沿的技术支持和更精准的大数据支撑，急客户所急，想客户所想，您需要的，才是我们最关注的。
        </p>
        <div class="row jc-start ai-center" style="margin-top:48px;">
          <div class="banner-btn2">点击下载</div>
        </div>
      </div>
      <img src="@/assets/banner4.png" alt="banner" class="banner4" />
    </div>
    <div class="contact-wrap-out">
      <div class="col jc-start ai-center contact-wrap">
        <div class="row jc-between ai-center form-wrap-out">
          <div class="col jc-start ai-start form-wrap">
            <div class="line"></div>
            <p class="desc-title2" style="color:#332218;">联系我们</p>
            <p class="desc-info" style="color:#332218;margin-top:-40px;">
              地址：广西南宁市青秀区双拥路32号宝资城大厦3楼303室
            </p>
            <div style="height:180px;"></div>
            <input type="text" name="name" class="input-text" placeholder="您的姓名" />
            <input type="text" name="email" class="input-text" placeholder="您的邮箱地址" />
            <input type="text" name="remark" class="input-text" placeholder="想对我们说" />
            <div class="submit-btn">提交</div>
          </div>
          <div id="map-container" class="map-wrap">
          </div>
        </div>
        <div class="row jc-around ai-center info-wrap-out">
          <div class="row jc-start ai-center info-wap" v-for="item in info" :key="item">
            <div class="row jc-center ai-center icon-wrap">
              <img :src="item.icon" alt="icon" class="info-icon" />
            </div>
            <div class="col jc-around ai-start" style="height:72px;">
              <p class="desc-info" style="color:#332218;font-weight:bold;">{{item.name}}</p>
              <p class="desc-info" style="color:#332218;">{{item.label}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row jc-center ai-center" 
      style="width:100%;background-color:#332218;height:130px;"
    >
      <div class="row jc-between ai-center logo-wrap">
        <div class="row jc-start ai-center">
          <img src="@/assets/logo.png" alt="logo" class="logo" />
          <div class="name" style="color:#fff;">全员考课APP</div>
        </div>
        <div class="col jc-around ai-start base-info">
          <p>Copyright All Rights Reserved. © 2021</p>
          <p>
            ICP备案：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:#999">桂ICP备19010174号 </a>
            桂公网安备：45010702001249号 
            网络文化经营许可证：桂网文(2020)3484-111号
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Wechat from '@/assets/wechat.png';
import Email from '@/assets/email.png';
import Clock from '@/assets/clock.png';
import Phone from '@/assets/phone.png';
export default {
  name: 'homePage',
  data: function() {
    return {
      info: [
        {name: '微信公众号', label: '广西数科院', icon: Wechat},
        {name: '邮箱', label: 'service@gxidt.cn', icon: Email},
        {name: '工作时间', label: '周一 - 周五 9:00 - 18:00', icon: Clock},
        {name: '电话', label: '0771-5540958', icon: Phone},
      ]
    }
  },
  mounted() {}
}
</script>
<style scope>
.container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo-wrap {
  width: 1400px;
  height: 88px;
}
.logo {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin-right: 15px;
}
.name {
  font-size: 19px;
  color: #292A34;
  font-weight: bold;
}
.xx-btn {
  width: 200px;
  height: 42px;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
  line-height: 42px;
}
.banner-wrap {
  width: 100%;
  height: 730px;
  background-image: linear-gradient(to right,#E9492F  0%, #FA6142 100%);
}
.banner-desc {
  width: 630px;
  height: 730px;
  box-sizing: border-box;
  margin-right: 30px;
  padding: 48px;
} 
.banner1 {
  width: 382px;
  height: 725px;
  margin-left: 40px;
  margin-top: 20px;
}
.desc-title {
  font-size: 18px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 5px;
  opacity: 0.6;
}
.desc-title2 {
  font-size: 60px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 50px;
}
.desc-info {
  font-size: 18px;
  color: #fff;
  opacity: 0.6;
  line-height: 30px;
}
.banner-btn1 {
  width: 170px;
  height: 48px;
  background-color: #FFDF99;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: #F3573A;
  font-weight: bold;
  margin-right: 25px;
  cursor: pointer;
}
.banner-btn2 {
  width: 170px;
  height: 48px;
  background-color: #E9492F;
  border: 1px solid #fff;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  cursor: pointer;
}
.banner-wrap2 {
  width: 100%;
  height: 800px;
  background-color: #fff;
}
.banner2 {
  width: 621px;
  height: 801px;
  margin-right: 40px;
}
.banner-desc2 {
  width: 730px;
  height: 730px;
  box-sizing: border-box;
  margin-left: 30px;
  padding: 48px;
} 
.desc2-title {
  font-size: 50px;
  color: #292A34;
  font-weight: bold;
  margin-bottom: 120px;
}
.banner-wrap3 {
  width: 100%;
  height: 940px;
  background-color: rgba(249,249,249,0.6);
}
.banner3 {
  width: 1400px;
  height: 640px;
}
.desc3-title {
  font-size: 45px;
  color: #332218;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 15px;
}
.banner-wrap4 {
  width: 100%;
  height: 850px;
  background-color: rgba(249,249,249,0.6);
}
.banner4 {
  width: 692px;
  height: 850px;
}
.base-info {
  width: 900px;
  height: 100px;
  font-size: 14px;
  color: #fff;
}
.contact-wrap-out {
  width: 100%;
  height: 700px;
  background-color: #fff;
  position: relative;
}
.contact-wrap {
  width: 1400px;
  height: 700px;
  position: absolute;
  left: 50%;
  top: -100px;
  margin-left: -700px;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px;
  box-sizing: border-box;
}
.form-wrap-out {
  width: 100%;
  height: 500px;
}
.form-wrap {
  width: 600px;
  height: 500px;
}
.map-wrap {
  width: 600px;
  height: 500px;
  margin-top: 30px;
}
.line {
  width: 50px;
  height: 8px;
  background-color: #E9492F;
  margin-top: 50px;
}
.input-text {
  width: 480px;
  height: 48px;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #E9E9E9;
  border-radius: 24px;
  text-align: start;
  padding-left: 18px;
  line-height: 48px;
  font-size: 16px;
  color: #332218;
  opacity: 0.6;
  margin-top: 15px;
}
.submit-btn {
  width: 480px;
  height: 48px;
  background-color: #E9492F;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
}
.info-wrap-out {
  width: 1400px;
  height: 200px;
  margin-top: 100px;
}
.info-wap {
  width: 300px;
  height: 72px;
}
.icon-wrap {
  width: 72px;
  height: 72px;
  background: #F1F1F1;
  border-radius: 72px;
  margin-right: 30px;
}
.info-icon {
  width: 32px;
  height: 32px;
}
.qrcode {
  width: 200px;
  height: 200px;
}
</style>